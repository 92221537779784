import { createStore } from 'vuex'
import ColorSettings from '../color-settings.js'
import squaresData from '../squares-data.js'

const LOCAL_STORAGE_KEYS = {
  size: 'size',
  allowReplacingSquares: 'allowReplacingSquares',
  allowReplacingSquaresLimit: 'allowReplacingSquaresLimit',
  checkColor: 'checkColor',
  excludedSquares: 'excludedSquares',
  gameSquares: 'gameSquares',
  checkedSquares: 'checkedSquares',
  finished: 'finished',
  volumeMute: 'volumeMute',
  audioHelpActive: 'audioHelpActive',
  titleHelpActive: 'titleHelpActive'
}

export default createStore({
  state: {
    settings: {
      colAndRowSize: 5,
      excludedSquares: [],
      checkColor: 'yellow',
      volumeMute: false,
      allowReplacingSquares: false,
      allowReplacingSquaresLimit: 5,
    },
    game: {
      squares: [],
      checkedSquares: [],
      finished: true,
    },
    audioHelpActive: false,
    titleHelpActive: false
  },
  mutations: {
    saveColAndRowSize: (state, size) => {
        localStorage.setItem(LOCAL_STORAGE_KEYS.size, size);
       {state.settings.colAndRowSize = size;}
    },
    initDataFromLocalStorage: (state) => {
      //Col and row size
      if (localStorage.getItem(LOCAL_STORAGE_KEYS.size)) {
        {state.settings.colAndRowSize = parseInt(localStorage.getItem(LOCAL_STORAGE_KEYS.size))}
      } 
      
      //Allow replacing squares
      if (localStorage.getItem(LOCAL_STORAGE_KEYS.allowReplacingSquares)) {
        {state.settings.allowReplacingSquares = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.allowReplacingSquares))}
      }

      //Allow replacing squares limit
      if (localStorage.getItem(LOCAL_STORAGE_KEYS.allowReplacingSquaresLimit)) {
        {state.settings.allowReplacingSquaresLimit = parseInt(localStorage.getItem(LOCAL_STORAGE_KEYS.allowReplacingSquaresLimit))}
      }
      
      //Check color
      if (localStorage.getItem(LOCAL_STORAGE_KEYS.checkColor)) {
        {state.settings.checkColor = localStorage.getItem(LOCAL_STORAGE_KEYS.checkColor)}
      } 
      
      //Excluded squares
      if (localStorage.getItem(LOCAL_STORAGE_KEYS.excludedSquares)) {
        {state.settings.excludedSquares = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.excludedSquares))}
      } else {
        // Add default excluded squares
        const squares = squaresData.OPTIONS;
        for (let i = 0; i < squares.length; i++) {
          if (!squares[i].selectedFromStart) {
            state.settings.excludedSquares.push(squares[i].name);
          }
        }
        localStorage.setItem(LOCAL_STORAGE_KEYS.excludedSquares, JSON.stringify(state.settings.excludedSquares));
      }
      
      //Game squares
      if (localStorage.getItem(LOCAL_STORAGE_KEYS.gameSquares)) {
        {state.game.squares = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.gameSquares))}
      }
      //Checked squares
      if (localStorage.getItem(LOCAL_STORAGE_KEYS.checkedSquares)) {
        {state.game.checkedSquares = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.checkedSquares))}
      }
      //Finished
      if (localStorage.getItem(LOCAL_STORAGE_KEYS.finished)) {
        {state.game.finished = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.finished))}
      }

      //Volume mute
      if (localStorage.getItem(LOCAL_STORAGE_KEYS.volumeMute)) {
        {state.settings.volumeMute = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.volumeMute))}
      }      
    }, 
    addToExcludedSquares: (state, payload) => {
      state.settings.excludedSquares.push(payload.name);
      localStorage.setItem(LOCAL_STORAGE_KEYS.excludedSquares, JSON.stringify(state.settings.excludedSquares));
    },       
    removeFromExcludedSquares: (state, payload) => {
      state.settings.excludedSquares.splice(state.settings.excludedSquares.indexOf(payload.name), 1);
      localStorage.setItem(LOCAL_STORAGE_KEYS.excludedSquares, JSON.stringify(state.settings.excludedSquares));
    },
    setGameSquares: (state, payload) => {
      state.game.squares = payload;
      localStorage.setItem(LOCAL_STORAGE_KEYS.gameSquares, JSON.stringify(state.game.squares));
    },
    setGameFinished: (state, payload) => {
      state.game.finished = payload;
      localStorage.setItem(LOCAL_STORAGE_KEYS.finished, JSON.stringify(state.game.finished));
    },
    addToCheckedSquares: (state, payload) => {
      state.game.checkedSquares.push(payload.name);
      localStorage.setItem(LOCAL_STORAGE_KEYS.checkedSquares, JSON.stringify(state.game.checkedSquares));
    },       
    removeFromCheckedSquares: (state, payload) => {
      state.game.checkedSquares.splice(state.game.checkedSquares.indexOf(payload.name), 1);
      localStorage.setItem(LOCAL_STORAGE_KEYS.checkedSquares, JSON.stringify(state.game.checkedSquares));
    },
    clearCheckedSquares: (state) => {
      state.game.checkedSquares = [];
      localStorage.setItem(LOCAL_STORAGE_KEYS.checkedSquares, JSON.stringify(state.game.checkedSquares));
    },
    refreshNonFoundSquares: (state) => {
      // Use squaresData.OPTIONS as the source of all available squares
      let squaresInPlay = squaresData.OPTIONS;
  
      // Get the current game state
      let currentSquares = state.game.squares;
      let checkedSquares = state.game.checkedSquares;
  
      // Filter out excluded squares and already found (checked) squares
      let excludedSquares = state.settings.excludedSquares;
      
      // Squares available to be randomly selected
      let availableSquares = squaresInPlay.filter(function(item) {
        return excludedSquares.indexOf(item.name) < 0 && 
               checkedSquares.indexOf(item.name) < 0;
      });
  
      // Replace non-checked squares with new random ones
      let refreshedSquares = currentSquares.map(square => {
        if (checkedSquares.indexOf(square.name) >= 0) {
          // Keep the checked squares as is
          return square;
        } else {
          // Select a new square randomly from available ones
          let randIndex = Math.floor(Math.random() * availableSquares.length);
          let newSquare = availableSquares.splice(randIndex, 1)[0]; // Remove and return the new square
          return newSquare;
        }
      });
  
      // Commit the refreshed squares back to the game state
      state.game.squares = refreshedSquares;
      localStorage.setItem(LOCAL_STORAGE_KEYS.gameSquares, JSON.stringify(state.game.squares));
    },
    setCheckColor: (state, payload) => {
      state.settings.checkColor = payload;
      localStorage.setItem(LOCAL_STORAGE_KEYS.checkColor, state.settings.checkColor);
    },    
    setAudioHelpActive: (state, payload) => {
      state.audioHelpActive = payload;
    },    
    setTitleHelpActive: (state, payload) => {
      state.titleHelpActive = payload;
    },    
    setVolumeMute: (state, payload) => {
      state.settings.volumeMute = payload;
      localStorage.setItem(LOCAL_STORAGE_KEYS.volumeMute, JSON.stringify(state.settings.volumeMute));
    },  
    setAllowReplacingSquares(state, value) {
      state.settings.allowReplacingSquares = value;
      localStorage.setItem(LOCAL_STORAGE_KEYS.allowReplacingSquares, JSON.stringify(state.settings.allowReplacingSquares));
    },  
    setAllowReplacingSquaresLimit(state, value) {
      state.settings.allowReplacingSquaresLimit = value;
      localStorage.setItem(LOCAL_STORAGE_KEYS.allowReplacingSquaresLimit, state.settings.allowReplacingSquaresLimit);
    }    
  },
  actions: {
    toggleExcludeSquare (context, payload) {
      if(context.state.settings.excludedSquares.indexOf(payload.name) >= 0){
        context.commit('removeFromExcludedSquares', payload);
      } else {
        context.commit('addToExcludedSquares', payload);
      }
    },
    toggleCheckedSquare (context, payload) {
      if(context.state.game.checkedSquares.indexOf(payload.name) >= 0){
        context.commit('removeFromCheckedSquares', payload);
      } else {
        context.commit('addToCheckedSquares', payload);
      }
    },
  },
  modules: {
  },
  getters: {
    currentCheckColor: state => {
      for(var i=0; i < ColorSettings.COLORS.length; i++){
        if(state.settings.checkColor === ColorSettings.COLORS[i].name){
          return ColorSettings.COLORS[i];
        }
      }
      return ColorSettings.COLORS[0];
    },
    bingoRows: state => {
      var bingoRows = [];
      var checkedSquares = state.game.checkedSquares;
      var squares = state.game.squares;
      var size = Math.sqrt(squares.length);            
      for(var i=1; i<= size; i++){
        var allChecked = true;
        for(var j=1; j<= size; j++){
          var index = ((i-1) * size) +(j-1);
          var name = squares[index].name;
          if(checkedSquares.indexOf(name) < 0){
            allChecked = false;
          }
        }
        if(allChecked){
          bingoRows.push(i);
        }
      }
      return bingoRows;
    },
    bingoCols: state => {
      var bingoCols = [];
      var checkedSquares = state.game.checkedSquares;
      var squares = state.game.squares;
      var size = Math.sqrt(squares.length);
      for(var i=1; i<= size; i++){
        var allChecked = true;
        for(var j=1; j <= size; j++){
          var index = ((j-1) * size) +(i-1);
          var name = squares[index].name;
          if(checkedSquares.indexOf(name) < 0){
            allChecked = false;
          }
        }
        if(allChecked){
          bingoCols.push(i);
        }
      }
      return bingoCols;
    },
    diagonalLeftTopRightBottomIndexes: state => {
      var leftTopRightBottomIndexes = []; 
      var squares = state.game.squares;
      var size = Math.sqrt(squares.length);      
      for(var i=1; i<= size; i++){
        var index = (i-1)*size +(i-1);
        leftTopRightBottomIndexes.push(index);              
      }
      return leftTopRightBottomIndexes;
    },
    diagonalRightTopLeftBottomIndexes: state => {
      var rightTopLeftBottomIndexes = []; 
      var squares = state.game.squares;
      var size = Math.sqrt(squares.length);      
      for(var i=1; i<= size; i++){
        var index = i*(size-1);
        rightTopLeftBottomIndexes.push(index);              
      }
      return rightTopLeftBottomIndexes;
    },
    bingoDiagonals: (state, getters) => {
      var bingoDiagonals = [];
      var checkedSquares = state.game.checkedSquares;
      var squares = state.game.squares;
      var leftTopRightBottomIndexes = getters.diagonalLeftTopRightBottomIndexes;
      var rightTopLeftBottomIndexes = getters.diagonalRightTopLeftBottomIndexes;

      //LEFT TOP
      var allChecked = true;
      leftTopRightBottomIndexes.forEach(function(index){
        var name = squares[index].name;
        if(checkedSquares.indexOf(name) < 0){
          allChecked = false;
        }
      });  
      if(allChecked){
        bingoDiagonals.push("LEFT-TOP");
      }    
      

      //RIGHT TOP
      allChecked = true;
      rightTopLeftBottomIndexes.forEach(function(index){
        var name = squares[index].name;
        if(checkedSquares.indexOf(name) < 0){
          allChecked = false;
        }
      });  
      if(allChecked){
        bingoDiagonals.push("RIGHT-TOP");
      }    
            
      return bingoDiagonals;
    },

  }  
})
