<template>

<header class="header hide-for-print" role="banner">
    <nav id="nav" class="nav" v-bind:class="[showMenu ? 'nav--open' : '']" role="navigation">
      <div class="nav__brand">
        <router-link to="/">Barnens bilbingo</router-link>
      </div>
      <ul class="nav__menu " id="menu" tabindex="-1" aria-label="Huvudmeny" >        
          <li class="nav__item" v-on:click="showMenu = !showMenu"><router-link to="/">Skapa ny bingobricka</router-link></li>
          <li class="nav__item" v-on:click="showMenu = !showMenu"><router-link to="/bingo">Min bingobricka</router-link></li>
          <li class="nav__item" v-on:click="showMenu = !showMenu"><router-link to="/vad-ar-bilbingo">Vad är bilbingo?</router-link></li>
          <li class="nav__item" v-on:click="showMenu = !showMenu"><router-link to="/om">Om Barnens bilbingo</router-link></li>
          <li class="nav__item" v-on:click="showMenu = !showMenu"><router-link to="/bilbingo-som-app">App</router-link></li>
      </ul>      
      <button class="nav__toggle" role="button" v-on:click="showMenu = !showMenu"  aria-expanded="false" aria-controls="menu">
        <svg class="menuicon" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
          <title>Visa meny</title>
          <g>
            <line class="menuicon__bar" x1="13" y1="16.5" x2="37" y2="16.5"/>
            <line class="menuicon__bar" x1="13" y1="24.5" x2="37" y2="24.5"/>
            <line class="menuicon__bar" x1="13" y1="24.5" x2="37" y2="24.5"/>
            <line class="menuicon__bar" x1="13" y1="32.5" x2="37" y2="32.5"/>
            <circle class="menuicon__circle" r="23" cx="25" cy="25" />
          </g>
        </svg>
      </button> 
      <div class="splash"></div>      
    </nav>
  </header>

  <main>
  <router-view/>
  </main>
  
  <animated-car></animated-car>
</template>


<script>
import AnimatedCar from '@/components/AnimatedCar.vue'
import update from './mixins/update'

export default {
  name: 'App',
  data: function(){
    return {
      showMenu: false
    }
  },
  components: {
    AnimatedCar
  },
  mixins: [update],
  beforeCreate() {
    this.$store.commit('initDataFromLocalStorage');
  },  
}

</script>

<style lang="scss">
html, body {
  margin:0px;
  padding: 0px;
}

body, html {
  height: 100%;
  width: 100%;
  //overflow: hidden;
}

body {
	background: rgb(209,228,234);
  background: -moz-radial-gradient(center, ellipse cover,  rgba(209,228,234,1) 0%, rgba(186,228,244,1) 100%);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,rgba(209,228,234,1)), color-stop(100%,rgba(186,228,244,1)));
  background: -webkit-radial-gradient(center, ellipse cover,  rgba(209,228,234,1) 0%,rgba(186,228,244,1) 100%);
  background: -o-radial-gradient(center, ellipse cover,  rgba(209,228,234,1) 0%,rgba(186,228,244,1) 100%);
  background: -ms-radial-gradient(center, ellipse cover,  rgba(209,228,234,1) 0%,rgba(186,228,244,1) 100%);
  background: radial-gradient(ellipse at center,  rgba(209,228,234,1) 0%,rgba(186,228,244,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d1e4ea', endColorstr='#bae4f4',GradientType=1 );

	padding:0;
	margin:0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333;
}

.app-version {
  position: absolute;
  bottom: 20px;
  right: 20px;  
}

footer {
  margin-top: 80px;
  min-height: 70px;
  padding:20px 10px;
  background-color: #9BF6FF;
}

main {
  padding-top: 0px;
  position: relative;
}

p {
  text-align: left;
  max-width: 700px;
  margin: auto;
}
.mobile-padding {
  padding:0 2rem;
}

// ---------------------------
// Vars & Helper Functions
// ---------------------------

:root {
  --header-bg-color:  #f77272;
  --splash-bg-color:  #d6de5f;
  //--screen-height:  100vh;
}

// calculate a circle's circumference based on radius
@function circumference($r){
  $pi: 3.141592653;
  @return 2*$pi*$r;
}


// ---------------------------
// Main Navigation Menu
// ---------------------------

.nav {
  height: 30px;
  margin-bottom: 20px;
  background-color:var(--header-bg-color);
  padding: 30px;
  font-size:25px;

  // Toggle Button
  &__toggle {
    display:inline-block;
    position:absolute;
    z-index:1000;
    padding:0;
    border:0;
    background:transparent;
    outline:0;
    right:15px;
    top:25px;
    cursor:pointer;
    border-radius:50%;
    transition:background-color .15s linear;
    
    &:hover,
    &:focus {
      background-color:rgba(0,0,0,.5);
    }
  }
  
  &__menu {
    display:flex;
    flex-direction:column;
    justify-content:center;
    height:var(--screen-height);
    position:relative;
    z-index:500;
    visibility:hidden;
    list-style: none;
    margin:80px 0px 0px;
    padding:0px;
  }

  &__item {
    margin-top: 20px;
    opacity:0;

    
    transition:none;
    a {
      text-decoration: none;
      color:#333;
      font-weight: bold;
      &:hover, &:focus, &.router-link-exact-active {
        text-decoration: underline;
      }
    }
  }
  @for $i from 1 through 5 {
    &__item:nth-child(#{$i}){
      transform:translateY(-40px * $i);
    }
  }
  
  &__brand {
    text-align: left;
    a {
      color:#fff;
      text-decoration: none;
      font-weight: bold;
    }
  }
}


// ---------------------------
// SVG Menu Icon
// ---------------------------

.menuicon {
  display:block;
  cursor:pointer;
  color: white;
  
  transform:rotate(0deg);
  transition: .3s cubic-bezier(0.165, 0.840, 0.440, 1.000); 
  
  &__bar,
  &__circle {
    fill:none;
    stroke: currentColor;
    stroke-width:3;
    stroke-linecap:round;
  }
  &__bar {
    transform: rotate(0deg);
    transform-origin:50% 50%;
    transition: transform .25s ease-in-out;
  }
  &__circle {
    transition: stroke-dashoffset .3s linear .1s;
    stroke-dashoffset:circumference(23); // 23 is the <circle>'s radius
    stroke-dasharray:circumference(23);
  }
}


// ---------------------------
// Splash Background
// ---------------------------

.splash {
  position:fixed;
  top:0px;
  right:0px;
  width: 0px;
  height: 100%;
  z-index: 100;
  transition: all 300ms ease;
  background-color:var(--splash-bg-color);
}
  
// ---------------------------
// Active State
// ---------------------------

.nav:target,
.nav--open {
  
  //scale the background circle to full size
  > .splash {
    transform:scale(1);
    width:100%;
  }
  
  //animate the menu icon
  .menuicon {
    color:white;
    transform:rotate(180deg);
    
    &__circle {
      stroke-dashoffset:0;
    }
    &__bar:nth-child(1),
    &__bar:nth-child(4) {
      opacity:0;
    }
    &__bar:nth-child(2) {
      transform: rotate(45deg);
    }
    &__bar:nth-child(3) {
      transform: rotate(-45deg);
    }
  }
  
  //show the nav items
  .nav {
    &__menu {
      visibility:visible;
    }
  
    &__item {
      transition: all .3s cubic-bezier(0.000, 0.995, 0.990, 1.000) .3s;
      opacity:1;
      transform:translateY(0);
    }
  }
}

// ---------------------------
// COMMON STYLES
// ---------------------------

h1 {
  margin-top:40px;
}
p a {
  color:#f67272;
  &:hover, &:focus {
    font-weight: bold;
  }
}
p.ingress {
  font-size:1.3rem;
  max-width:700px;
  margin:auto;
  text-align: center;
}
.btn {
  border-radius: 4px;
  border: 0px;
  padding: 20px;
  font-size: 1.0rem;
  transition: all 200ms ease;
}

.btn-group {
  .btn {
    margin-right: 10px;
    cursor:pointer; 
    margin-bottom: 10px;   
  }
}


label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;  
}

.btn-option {
  $bgColor: #f77272;
  background:#fff;
  color:#000;
  border:1px solid $bgColor;
  &:hover, &:focus{
    background:lighten($bgColor, 10%);
    color:#fff;
  }
   &.selected  {
     background:$bgColor;
   }
}

.btn-secondary {
  background:#fff;
  border:1px solid #000;
  color:#000;
  &:hover, &:focus, &.selected  {
    background:#000;
    color:#fff;
  }
}

.btn-primary {
  $bgColor: #f77272;
  color:#fff;
  background:$bgColor;
  border:1px solid $bgColor;
  margin-bottom: 20px;
  &:hover, &:focus, &.selected  {
    $bgColor: darken($bgColor, 10%);
    background:$bgColor;
    //border:1px solid #fff;
  }
  &:active  {
    $bgColor: darken($bgColor, 20%);
    background:$bgColor;
  }  
}

.btn-large {
    font-size:1.5rem;
}

.btn-disabled {
  opacity:0.5;
}

.banner-link {
  display:flex;
  border-radius: 4px;
  background:#fff;
  border:1px solid #f77272;
  padding:10px;
  color:#333;
  margin:0 1rem 1rem;
  text-decoration: none;
  align-items: center;
  text-align: left;
  transition: all 200ms ease;
  i {
    font-size: 2rem;
    margin-right: 10px;
  }
  &:hover, &:focus {
    background-color:#f77272;
    color:#fff;
  }
}

@media screen {
  .show-for-print-only {
    display:none !important;
  }
}

@media print {
  .hide-for-print {
    display:none !important;
  }
  .print-header{
      font-size: 3rem;
      font-weight: bold;
  }
}

</style>


