<template>
  <div class="home">
    <div v-if="!finished">
        <router-link class="banner-link" to="/bingo"><i class="fas fa-play"></i> <div><strong>Fortsätt spela!</strong><br>Du har redan ett spel igång.</div></router-link>
    </div>
    <h1 class="mobile-padding">
      Välkommen till Barnens bilbingo!
    </h1>
    <p class="mobile-padding ingress">
      Barnens bilbingo är ett gratis bilbingo utan reklam som du kan spela direkt på din telefon eller surfplatta.
    </p>
    <!-- <p class="mobile-padding ingress" style="margin-top:1rem">
      Välj hur många rutor du vill att din bingobricka ska innehålla och klicka sedan på "Skapa ny bingobricka" för att börja spela. Om du önskar kan du även själv välja vilka rutor som ska användas.
    </p> -->

    <div class="options-group mobile-padding" style="margin-top:40px">
      <label>1. Hur många rutor vill du ha på din bricka?</label>
      <div class="btn-group">
        <button type="button" v-on:click="saveColAndRowSize(3);" v-bind:class="[size === 3 ? 'selected' : '']" class="btn btn-option">3x3</button>
        <button type="button" v-on:click="saveColAndRowSize(4);" v-bind:class="[size === 4 ? 'selected' : '']" class="btn btn-option">4x4</button>
        <button type="button" v-on:click="saveColAndRowSize(5);" v-bind:class="[size === 5 ? 'selected' : '']" class="btn btn-option">5x5</button>
        <button type="button" v-on:click="saveColAndRowSize(6);" v-bind:class="[size === 6 ? 'selected' : '']" class="btn btn-option">6x6</button>
        <button type="button" v-on:click="saveColAndRowSize(7);" v-bind:class="[size === 7 ? 'selected' : '']" class="btn btn-option">7x7</button>
      </div>
    </div>

    <div class="options-group">
      <label>2. Vilka rutor ska användas?</label>
      <button type="button" class="btn btn-secondary" v-on:click="showAdvanced = !showAdvanced">
        <div v-if="showAdvanced">
          <i class="fas fa-th"></i> Stäng rut-väljaren ({{numberOfSquaresInPlay}} valda)
        </div> 
        <div v-else>
          <i class="fas fa-th"></i> Öppna rut-väljaren ({{numberOfSquaresInPlay}} valda)
        </div>
      </button>
    </div>

    <div class="options-group options-group--with-background" v-show="showAdvanced">
      <h2>Välj rutor</h2>
      <p>
        Klicka på de rutor som du <strong>inte</strong> vill använda i spelet.
      </p>
      <p style="margin-top:1rem">
        Du har valt bort {{excludedSquares.length}} brickor. Det finns nu {{numberOfSquaresInPlay}} brickor som kan lottas till din bingobricka.
      </p>
      <div v-for="category in getSquareCategories()" :key="category">
        <div class="options-group">
          <label>{{category}}</label>
          <div class="options-group__bingo-squares">
            <BingoSquare v-for="square in getSquaresForCategory(category)" 
              :key="square.name" 
              :show-title="true" 
              :square-data="square"
              :check-type="'crossover'"
              :initial-checked="isSquareChecked(square)" 
              @onClick="toggleExclude"
            />
          </div>
        </div>
      </div>

    </div>    


    <div class="options-group mobile-padding">
      <label>3. Vilka regler?</label>
      <label for="allowReplacingSquares">        
        <input type="checkbox" id="allowReplacingSquares" v-model="allowReplacingSquares">
        Tillåt byte av brickor under spelets gång
      </label>
      <div class="options-group" v-if="allowReplacingSquares">
        <label style="font-weight: 300" for="allowReplacingSquaresLimit">
          Du måste vänta {{allowReplacingSquaresLimit}} {{allowReplacingSquaresLimitText}} mellan byten av brickor
        </label>        
        <input type="range" id="allowReplacingSquaresLimit" v-on:change= "saveAllowReplacingSquaresLimit" v-model="allowReplacingSquaresLimit" min="1" max="15">
      </div>
    </div>


    <div class="options-group"  style="margin-top:30px">
      <div v-if="!enoughSquaresActive" class="hide-for-print mobile-padding" style="margin-bottom:1rem">
        <p style="text-align:center">
          <strong>Obs! Du måste aktivera fler rutor i rut-väljaren för att kunna starta spelet.</strong>
        </p>
      </div>
      <button type="button" class="btn btn-primary btn-large" v-bind:class="[!enoughSquaresActive ? 'btn-disabled' : '']"  :disabled="!enoughSquaresActive" v-on:click="generateBingoSquaresForGame">
        Skapa ny bingobricka
      </button>
    </div>

    <p class="mobile-padding" style="margin-top:40px">
      När du skapar en ny bingobricka fylls den med slumpvis valda rutor.
    </p>
    <p class="mobile-padding" style="margin-top:20px">
      Pssst! Barnens bilbingo är byggt som en webbsida men du kan lägga till sidan på din hemskärm så fungerar det i princip som en app.
      <br><br>
      Pssst igen! Vill du hellre spela med papper och penna går det bra att skapa bingobrickor och sedan skriva ut dessa.
    </p>
  </div>
</template>

<script>
// @ is an alias to /src

import router from '../router'
import squaresData from '../squares-data.js'
import BingoSquare from '@/components/BingoSquare.vue'

export default {
  name: 'Home',
  data: function(){
    return {
      showAdvanced: false,
      allowReplacingSquares: false,
      allowReplacingSquaresLimit: 1
    }
  },
  mounted() {
    this.allowReplacingSquares = this.$store.state.settings.allowReplacingSquares
    this.allowReplacingSquaresLimit = this.$store.state.settings.allowReplacingSquaresLimit ? this.$store.state.settings.allowReplacingSquaresLimit : 1
  },
  computed: {
    size(){
      return this.$store.state.settings.colAndRowSize;
    },
    finished(){
      return this.$store.state.game.finished;
    },
    excludedSquares(){
      return this.$store.state.settings.excludedSquares;
    },
    numberOfSquaresInPlay(){
      return squaresData.OPTIONS.length - this.$store.state.settings.excludedSquares.length;
    },
    state(){
      return this.$store.state;
    },
    enoughSquaresActive(){
      const numberOfSquaresNeededForGame = this.size * this.size;
      const numberOfActiveSquares = squaresData.OPTIONS.length - this.excludedSquares.length;
      return numberOfActiveSquares >= numberOfSquaresNeededForGame;
    },
    allowReplacingSquaresLimitText(){
      return this.allowReplacingSquaresLimit == 1 ? 'minut' : 'minuter';
    }
  },
  methods: {
    classObject(){
     return 'selected';
    },    
    saveColAndRowSize(size){
      this.$store.commit('saveColAndRowSize', size);
    },
    getSquareCategories(){
      return squaresData.CATEGORIES;
    },
    getSquaresForCategory(category){
      var newArray = squaresData.OPTIONS.filter(function(item)
      {
        return item.categories.indexOf(category) >= 0;
      });      

      //newArray = newArray.sort((a, b) => a.title > b.title ? 1 : -1);

      return newArray;
    },
    generateBingoSquaresForGame(){
      if(!this.enoughSquaresActive){
        return;
      }
      var squaresForGame = [];

      const numberOfSquaresForGame = this.size * this.size;
      var excludedSquares = this.excludedSquares;
      var squaresInPlay = squaresData.OPTIONS.filter(function(item){
        return excludedSquares.indexOf(item.name) < 0;
      });      
      for (var i = squaresInPlay.length - 1; i > 0; i--) {
          var rand = Math.floor(Math.random() * (i + 1));
          [squaresInPlay[i], squaresInPlay[rand]] = [squaresInPlay[rand], squaresInPlay[i]]
      }
      if(squaresInPlay.length >= numberOfSquaresForGame){
          squaresForGame = squaresInPlay.slice(0, numberOfSquaresForGame);
          this.$store.commit('setGameSquares', squaresForGame);   
          this.$store.commit('clearCheckedSquares');   
          this.$store.commit('setAudioHelpActive', false);
          this.$store.commit('setGameFinished', false);
          console.log(this.allowReplacingSquares);
          this.$store.commit('setAllowReplacingSquares', this.allowReplacingSquares);
          router.push({ path: 'bingo' });
      }
      // let maxIterations = 100;
      // let iterations = 0;
      // while(squaresForGame.length < numberOfSquaresForGame && iterations <= maxIterations){
      //   iterations++;
      // }  
    },
    isSquareChecked(squareData){
      return this.excludedSquares.indexOf(squareData.name) >= 0;
    },
    toggleExclude(squareData){
      this.$store.dispatch('toggleExcludeSquare', {
        name: squareData.name
      });
    },
    saveAllowReplacingSquaresLimit(){
      console.log(this.allowReplacingSquaresLimit);
      this.$store.commit('setAllowReplacingSquaresLimit', this.allowReplacingSquaresLimit);
    }  
  },
  components: {
    BingoSquare
  }
}
</script>


<style scoped lang="scss">
.options-group {
  margin-top: 20px;

  &--with-background {
    padding:20px;
    background-color: #fefefe;
  }
  &__bingo-squares {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin:10px;
  }
}

.bingo-square {
    flex: 1 0 auto;
    height: 100px;
    width:100px;
    flex-grow: 0;
    border-width:1px !important;
    margin:5px 5px 30px;
    &__content {
      flex-wrap:wrap;
    }
    background-color:#fff !important;
}

/*Range Reset*/
input[type="range"] {
   -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 17rem;
    margin-top: 15px;
}

/* Removes default focus */
input[type="range"]:focus {
  outline: none;
}

/***** Chrome, Safari, Opera and Edge Chromium styles *****/
/* slider track */
input[type="range"]::-webkit-slider-runnable-track {
   background-color: #fff;
   border-radius: 0.5rem;
   height: 1rem;  
}

/* slider thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
   appearance: none;
   margin-top: -8px; /* Centers thumb on the track */

   /*custom styles*/
   border-radius: 50%;
   background-color: #f77272;
   height: 2rem;
   width: 2rem;
}

input[type="range"]:focus::-webkit-slider-thumb {   
  border: 1px solid #fff;
  // outline: 3px solid #fff;
  // outline-offset: 0.125rem; 
}

/******** Firefox styles ********/
/* slider track */
input[type="range"]::-moz-range-track {
   background-color: #fff;
   border-radius: 0.5rem;
   height: 1rem;
}

/* slider thumb */
input[type="range"]::-moz-range-thumb {
   border: none; /*Removes extra border that FF applies*/
   border-radius: 0; /*Removes default border-radius that FF applies*/
   /*custom styles*/
   border-radius: 50%;
   background-color: #f77272;
   height: 2rem;
   width: 2rem;
}

input[type="range"]:focus::-moz-range-thumb {
  border: 1px solid #fff;
  // outline: 3px solid #fff;
  // outline-offset: 0.125rem; 
}

</style>
